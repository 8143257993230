import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
}

export const Stripe = memo(function Stripe({
  description,
  image,
  title,
}: Props) {
  return (
    <Container>
      <FadeInUp>
        <Background>{image ? <Image {...image} /> : null}</Background>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </FadeInUp>
    </Container>
  )
})

const Container = styled.div`
  width: calc(50% - 3.75rem);
  margin: 7.5rem 3.75rem 0 0;
  &:nth-of-type(even) {
    margin-top: 11.25rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 3.75rem auto;
    &:nth-of-type(even) {
      margin-top: 3.75rem;
    }

    > div {
      opacity: 1 !important;
      transform: none !important;
    }
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  margin-bottom: 2.5rem;
  padding-bottom: 71%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  padding-left: calc(2.778vw + 1rem);
  position: relative;
  &:before {
    content: '';
    width: 2.778vw;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryDark2};
    position: absolute;
    top: 1.125rem;
    left: 0;
  }

  @media (max-width: 767px) {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  letter-spacing: -0.0313rem;
  line-height: 2.25rem;
  margin-top: 2.25rem;

  @media (max-width: 424px) {
    line-height: 2rem;
  }
`
